<template>
  <v-btn text small @click="onExcelDownload"
    ><v-icon class="mr-1" small>mdi-download</v-icon>Выгрузить ответы
  </v-btn>
</template>

<script>
import Excel from '@/lib/excel.js'

export default {
  props: ['loadUrl', 'nosologia'],
  computed: {
    fio() {
      return this.$store.getters.getLoggedUser.fio
    },
  },
  methods: {
    async onExcelDownload() {
      const excel = new Excel({
        link: this.loadUrl,
        fileName: this.getFileName(),
        creatorName: this.fio,
      })
      excel.downloadAnswers()
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      const hh = addZero(date.getHours())
      const mm = addZero(date.getMinutes())
      const ss = addZero(date.getSeconds())
      return `${yyyy}${MM}${dd}_${hh}${mm}${ss}`
    },
    getFileName() {
      const assembledFileName = [
        this.fio.slice(0, 40),
        this.nosologia.name.slice(0, 80),
        this.getTimestamp(),
      ].join('_')

      const clearedFileName = assembledFileName
        .replace(/[:|*|?|"|<|>|||.|\\]/g, '-')
        .replace(/\s/g, '_')

      return clearedFileName + '.xlsx'
    },
  },
}
</script>
