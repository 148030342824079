/**
 * Проверяет проходит ли один критерий качества с этапа ГВС на этап НМИЦ
 * @param {Object} criteriaAnswers -- объект ответов на один критерий качества (users-criteria-stages.data)
 * @return {Boolean}
 */
export function isAnswerPassFromGVS(criteriaAnswers) {
  return criteriaAnswers.criteriaNecessity > 2
}

/**
 * Проверяет ответил ли ГВС на один критерий качества полностью
 * @param {Object} criteriaAnswers -- объект ответов на один критерий качества (users-criteria-stages.data)
 * @return {Boolean}
 */
export function isGVSAnswersReady(criteriaAnswers) {
  if (!criteriaAnswers) return false
  const { criteriaNecessity, criteriaStatus, isAmbulatory, isStationar } =
    criteriaAnswers
  if (criteriaNecessity === 0) return false
  if (criteriaStatus === 0) return false
  if (!isAmbulatory && !isStationar) return false
  return true
}

/**
 * Проверяет ответил ли НМИЦ на один критерий качества полностью
 * @param {Object} criteriaAnswers -- объект ответов на один критерий качества (users-criteria-stages.data)
 * @return {Boolean}
 */
export function isNMICAnswersReady(criteriaAnswers) {
  if (!criteriaAnswers) return false
  const { criteriaNecessity, criteriaAccessability } = criteriaAnswers
  if (!criteriaNecessity) return false
  if (!criteriaAccessability) return false
  return true
}

/**
 * Проверяет ответил ли ГВС на один критерий качества полностью
 * @param {Object} criteriaAnswers -- объект ответов на одно заболевание в проекте (userCriterionStages целиком)
 * @return {Number} -- число от 0 до 100. Процент готовности
 */
export function calculateNosologiaReadyPercentByGvs(userCriterionStages) {
  const answers = userCriterionStages.map(ucs => isGVSAnswersReady(ucs.data))
  const done = answers.filter(a => a)
  return Math.floor((done.length / answers.length) * 100)
}
