import { isIE } from './helpers'

/**
 * Типограф. ищет 1 или 2 не пробельных символа окруженных пробельными символами.
 * Заменяет последний пробел в найденном на неразрывный
 * @param { string } string - Исходная строка
 * @returns {string }
 */
export function typograph(string) {
  return isIE() ? string : string.trim().replaceAll(/(\s\S{1,2})\s/g, '$1\xa0')
}
