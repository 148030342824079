<template>
  <div class="d-flex align-center min-height-24">
    <v-icon
      class="mr-1"
      :class="{ rotate: isLoading }"
      :color="status.colorName"
    >
      {{ status.icon }}
    </v-icon>
    <span :class="status.textColorClass">{{ status.text }} </span>
  </div>
</template>

<script>
export default {
  props: [
    'isError',
    'isLoading',
    'hasChanges',
    'isReady',
    'isInitial',
    'disabled',
  ],
  computed: {
    status() {
      if (this.disabled) {
        return {
          text: 'Готово. Вы уже отправили ответы. Изменить отправленные ответы и комментарии нельзя.',
          icon: 'mdi-check-all',
          colorName: 'grey',
          textColorClass: 'grey--text',
        }
      }
      if (this.isInitial) {
        return {
          text: '',
          colorName: 'grey',
          textColorClass: 'grey--text',
        }
      }
      if (this.isError)
        return {
          text: 'Ошибка сохранения карточки',
          icon: 'mdi-alert',
          colorName: 'red',
          textColorClass: 'red--text',
        }
      if (this.isLoading)
        return {
          text: 'Сохраняю...',
          icon: 'mdi-loading',
          colorName: 'grey',
          textColorClass: 'grey--text',
        }
      if (this.hasChanges) {
        return {
          text: 'Есть несохраненный комментарий. Кликните по карточке, чтобы сохранить',
          icon: 'mdi-alert',
          colorName: 'orange',
          textColorClass: 'orange--text',
        }
      }
      if (!this.isReady) {
        return {
          text: 'Изменения сохранены',
          icon: 'mdi-check',
          colorName: 'grey',
          textColorClass: 'grey--text',
        }
      }
      return {
        text: 'Готово. Изменения сохранены и есть все ответы',
        icon: 'mdi-check-all',
        colorName: 'green',
        textColorClass: 'green--text',
      }
    },
    textColor() {
      return this.isError ? 'red--text' : 'grey--text'
    },
  },
}
</script>

<style scoped>
.min-height-24 {
  min-height: 24px;
}
.rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
