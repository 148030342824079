<template>
  <div>
    <LabelWithTooltip
      :label="label"
      :hint="hint"
      :hintFrom="hintFrom"
      class="mb-2"
    />

    <div>
      <v-radio-group
        :value="value"
        :disabled="disabled"
        readonly
        row
        hide-details
        class="mt-0 mb-2 pa-0"
      >
        <v-radio
          v-for="r in radio"
          :key="r.value"
          :label="r.label"
          :value="r.value"
          @click="r.clickHandler"
          class="mt-0 pa-0 mr-6"
        ></v-radio>
      </v-radio-group>
    </div>

    <CommentBlock
      :comment="comment"
      :disabled="disabled"
      @save="onCommentChange"
      @has-changes="throwEvent"
    />
  </div>
</template>

<script>
import CommentBlock from '@/components/gvs/CommentBlock.vue'
import LabelWithTooltip from '@/components/nmic/LabelWithTooltip.vue'

export default {
  components: { CommentBlock, LabelWithTooltip },
  props: [
    'value',
    'comment',
    'disabled',
    'label',
    'hint',
    'hintFrom',
    'fieldName',
    'commentFieldName',
  ],
  computed: {
    radio() {
      return [...Array(9).keys()].map(r => ({
        value: r + 1,
        label: `${r + 1}`,
        clickHandler: () => this.onRadioChange(r + 1),
      }))
    },
  },
  methods: {
    onRadioChange(n) {
      this.$emit('change', { name: this.fieldName, value: n })
    },
    onCommentChange(evt) {
      this.$emit('change', { name: this.commentFieldName, value: evt })
    },
    throwEvent(evt) {
      this.$emit('has-changes', evt)
    },
  },
}
</script>
