<template>
  <div v-if="isDone">
    Вы оценили все критерии для этого заболевания (состояния). Теперь отправьте
    ответы.
  </div>
  <div v-else>
    <span>Вы полностью оценили {{ amountCriteria }} из {{ total }}.</span>
    <span v-if="hideReady">
      <span v-if="hasChanges"
        >&nbsp;Сейчас на странице отображаются не все критерии. Обновите
      </span>
      <span v-else
        >&nbsp;Сейчас на странице только незавершенные критерии.
      </span>
    </span>
    <span v-else>&nbsp;Сейчас на странице показываю все критерии</span>
  </div>
</template>

<script>
import { declineByNumber } from '@/lib/declineByNumber'

export default {
  props: ['ready', 'total', 'hideReady', 'hasChanges', 'isDone'],
  computed: {
    amountCriteria() {
      const criteria = declineByNumber('критерий', this.ready)
      return `${this.ready} ${criteria}`
    },
  },
}
</script>
