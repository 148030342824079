<template>
  <div>
    <div class="mb-1" v-if="showButton">
      <span class="tip" @click="switchComment">{{ buttonText }}</span>
    </div>
    <v-textarea
      v-model="text"
      v-if="showComment"
      :disabled="disabled"
      solo
      :rows="5"
      auto-grow
      @change="onChange"
      @input="onInput"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  props: ['comment', 'disabled'],
  data() {
    return {
      showComment: false,
      text: '',
    }
  },
  created() {
    this.text = this.comment
  },
  methods: {
    switchComment() {
      this.showComment = !this.showComment
    },
    onInput() {
      if (this.text != this.comment) {
        this.$emit('has-changes', true)
      } else {
        this.$emit('has-changes', false)
      }
    },
    onChange() {
      this.$emit('save', this.text)
    },
  },
  computed: {
    showButton() {
      return !(this.disabled && !this.comment)
    },
    buttonText() {
      if (this.showComment) return 'Сохранить и свернуть'
      if (this.comment) return 'Показать комментарий'
      return 'Добавить комментарий'
    },
  },
}
</script>

<style scoped>
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.tip:hover {
  opacity: 0.4;
}
</style>
