<template>
  <div>
    <v-btn @click="onSend" color="primary" :disabled="!isDone"
      >Отправить ответы</v-btn
    >
    <div v-if="!isDone" class="red--text body-2 mt-1">
      Отправить ответы можно, только оценив все критерии.
    </div>
  </div>
</template>

<script>
import { sendGVSAnswers } from '@/lib/requestsAPI'
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'
import { routeParams } from '@/mixins/routeParams'

export default {
  props: ['isDone'],
  mixins: [sendDataWithToastNoRedirect, routeParams],
  methods: {
    onSend() {
      this.sendDataWithToastNoRedirect({
        action: sendGVSAnswers,
        data: this.userProjectNosologiaId,
        successMessage: 'Ответы отправлены',
        errorMessage: 'Ошибка отправки ответов',
        onSuccess: () => this.$router.go(),
      })
    },
  },
}
</script>
