<template>
  <div>
    <span>{{ beforeText }}</span>
    <v-tooltip bottom :max-width="600" color="white">
      <template v-slot:activator="{ on, attrs }">
        <span
          ><span v-bind="attrs" v-on="on" class="tooltip-activator-text">{{
            hintFrom
          }}</span
          ><sup class="tooltip-activator-sup">?</sup></span
        ></template
      >
      <div class="tooltip-content">{{ hint }}</div>
    </v-tooltip>
    <span>{{ afterText }}</span>
  </div>
</template>

<script>
export default {
  props: ['label', 'hint', 'hintFrom'],
  computed: {
    hintPosition() {
      return this.label.indexOf(this.hintFrom)
    },
    beforeText() {
      return this.label.slice(0, this.hintPosition)
    },
    afterText() {
      return this.label.slice(
        this.hintPosition + this.hintFrom.length,
        this.label.length
      )
    },
  },
}
</script>

<style scoped>
.tooltip-activator-text {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.4);
}
.tooltip-activator-sup {
  font-weight: bold;
}
.tooltip-content {
  border: 1px solid grey;
  background-color: white;
  opacity: 1;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  padding: 8px;
}
</style>
