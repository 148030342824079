<template>
  <div class="d-flex mh-60">
    <v-btn :disabled="!showHideReadyButton" @click="onHideReady" class="mr-4"
      >Скрыть завершенные</v-btn
    >
    <v-btn :disabled="!showShowAllButton" @click="onShowAll" class="mr-4"
      >Показать все</v-btn
    >
    <SendAnswersButton :isDone="isDone" />
  </div>
</template>

<script>
import SendAnswersButton from '@/components/gvs/SendAnswersButton.vue'

export default {
  props: ['isDone', 'hideReady', 'hasChanges'],
  components: {
    SendAnswersButton,
  },
  computed: {
    showHideReadyButton() {
      if (!this.hideReady) return true
      if (this.hideReady && this.hasChanges) return true
      return false
    },
    showShowAllButton() {
      if (this.hideReady) return true
      return false
    },
  },
  methods: {
    onHideReady() {
      this.$emit('hide-ready')
    },
    onShowAll() {
      this.$emit('show-all')
    },
  },
}
</script>

<style scoped>
.mh-60 {
  min-height: 60px;
}
</style>
